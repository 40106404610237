import React from "react"
import { Link } from "gatsby"
import "../components/layout.css"
import Layout from "../components/layout"

const Sample = () => {
  return (
    <Layout>
      <div className="w-full h-screen map md:p-8">
        <div className="text-center mt-16">
          <h1>Influencer List Sample</h1>
          <p className="mt-16 w-1/2 mx-auto text-left font-medium">
            Thank you for your interest in our influencer list. We hope you find
            value in this list of{" "}
            <span className="italic">100 instagram influencers</span>.
          </p>
          <div className="mt-4 w-1/2 mx-auto text-left">
            <p className="mb-1 font-thin text-gray-700">
              <span className="underline">One more thing</span>. We are always
              looking for feedback to improve our product even more. Feel free
              to click the feedback button on the right hand side of the screen.
              We would be happy to hear from you!
            </p>
          </div>
        </div>
        <div>
          <div className="mt-5 sm:mt-8 font-sans sm:flex sm:justify-center lg:justify-center">
            <div className="rounded-md shadow cursor-pointer">
              <a
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRE0pm8uy-ndM-4fV-huy3KlCkx3sRkU3QPhsEVeSzpcZVRQBPCysnGH-HEyLGUAIojGOcP4XbwqmMh/pubhtml"
                className="gradient w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
              >
                View Sample
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sample
